import { faCommentSlash } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

import { Avatar } from 'share-tribe/components';
import Alert from 'venue/components/messaging/Alert/Alert';
import EmptyState from 'venue/components/messaging/EmptyState/EmptyState';
import UserDisplayName from 'venue/components/users/UserDisplayName';
import Review from 'venue/models/Review.model';

import css from './Reviews.module.css';

interface ReviewsProps {
  hasError: boolean;
  reviews: Review[];
}

const Reviews = ({ reviews, hasError }: ReviewsProps) => {
  const intl = useIntl();

  return (
    <div className={css.root}>
      <h3>Reivews ({reviews.length})</h3>
      {hasError ? <Alert type="error">Error loading reviews.</Alert> : null}
      {reviews.length === 0 ? (
        <EmptyState icon={faCommentSlash}>
          <p>Looks like there are not any reviews for this Venue.</p>
        </EmptyState>
      ) : (
        <ul>
          {reviews.map(({ attributes: review, id: { uuid }, author }) => {
            const dateString = intl.formatDate(review.createdAt, {
              month: 'long',
              year: 'numeric',
            });
            return (
              <li className={css.review} key={uuid}>
                <Avatar user={author} />
                <div className={css.reviewContent}>
                  <p>{review.content}</p>
                  <div className={css.reviewMeta}>
                    <UserDisplayName user={author} />
                    <time dateTime={review.createdAt.toDateString()}>{dateString}</time>
                    <div className={css.reviewRating}>
                      {[1, 2, 3, 4, 5].map((star) => (
                        <span
                          key={`star-${star}`}
                          className={clsx(css.star, {
                            [css.starFilled]: star <= review.rating,
                            [css.starOutline]: star > review.rating,
                          })}
                          title={`Star rating ${star} of 5`}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Reviews;
