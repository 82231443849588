import React from 'react';

import Author from 'venue/models/Author.model';

interface DisplayNameProps {
  user: Author;
}

const UserDisplayName = ({ user }: DisplayNameProps) => {
  const { profile, deleted, banned } = user.attributes;

  const displayName = profile?.displayName
    ? profile.displayName
    : deleted
      ? 'Deleted user'
      : banned
        ? 'Banned user'
        : 'Anonymous user';
  return <span>{displayName}</span>;
};

export default UserDisplayName;
