import React from 'react';

import Listing from 'venue/models/Listing.model';

import AmenitiesSection from './AmenitiesSection/AmenitiesSection';
import CustomPricingSection from './CustomPricingSection/CustomPricingSection';
import css from './DetailSection.module.css';
import LocationSection from './LocationSection/LocationSection';
import OfferingsSection from './OfferingsSection/OfferingsSection';
import PoliciesSection from './PoliciesSection/PoliciesSection';

interface DetailSectionProps {
  title: string;
  type: 'amenities' | 'lineItems' | 'policies' | 'location' | 'customPricing';
  listing: Listing;
}

const DetailSection = ({ title, type, listing }: DetailSectionProps) => {
  return (
    <section className={css.root}>
      <div className={css.title}>
        <h5>{title}</h5>
      </div>
      <div className={css.content}>
        {type === 'amenities' ? (
          <AmenitiesSection publicData={listing.attributes.publicData} />
        ) : null}
        {type === 'location' ? <LocationSection listing={listing} /> : null}
        {type === 'policies' ? <PoliciesSection listing={listing} /> : null}
        {type === 'lineItems' ? <OfferingsSection listing={listing} /> : null}
        {type === 'customPricing' ? <CustomPricingSection listing={listing} /> : null}
      </div>
    </section>
  );
};

export default DetailSection;
