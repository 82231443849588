import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { AmenityKey, AmenityMapping } from 'venue/models/Amenity.model';
import Listing from 'venue/models/Listing.model';

import css from './AmenitiesSection.module.css';

interface AmenitiesSectionProps {
  publicData: Listing['attributes']['publicData'];
}

const AmenitiesSection = ({ publicData }: AmenitiesSectionProps) => {
  const amenties = Object.values(AmenityKey)
    .map((key) => {
      let amenity = null;
      if (key === AmenityKey.BringYourOwnAlcohol || key === AmenityKey.BringYourOwnFood) {
        const { byobf } = publicData;
        if (byobf?.includes(key)) {
          return AmenityMapping[key];
        }
      } else {
        amenity = publicData[key];
      }
      if (amenity === 'yes') {
        return AmenityMapping[key];
      }
      return null;
    })
    .filter(Boolean);

  const middleIndex = Math.ceil(amenties.length / 2);
  const firstHalf = amenties.slice(0, middleIndex);
  const secondHalf = amenties.slice(middleIndex);

  return (
    <div className={css.root}>
      <ul>
        {firstHalf.map((amenity, idx) => (
          <li key={`${amenity?.label}_${idx}`} className={css.amenity}>
            {amenity?.icon ? <FontAwesomeIcon icon={amenity.icon} /> : null}
            {amenity?.label}
          </li>
        ))}
      </ul>
      <ul>
        {secondHalf.map((amenity, idx) => (
          <li key={`${amenity?.label}_${idx}`} className={css.amenity}>
            {amenity?.icon ? <FontAwesomeIcon icon={amenity.icon} /> : null}
            {amenity?.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AmenitiesSection;
