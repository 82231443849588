export enum CustomPriceOverrideType {
  Hourly = 'Hourly price',
  PercentageHourly = 'Hourly price percentage',
  PercentageOverallTotal = 'Overall total percentage',
  PercentageOffering = 'Offerings percentage',
}

export enum TimeOfDay {
  FullDay = 'Full day',
  TimeRange = 'Time range',
}

export enum FlexibleDateOption {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  SpecificDate = 'Date',
}

export interface CustomPricing {
  flexibleDate: FlexibleDateOption;
  overrideType: CustomPriceOverrideType;
  timeOfDay: TimeOfDay;
  reason?: string;
  date?: {
    date: Date;
  };
  startTime?: number;
  endTime?: number;
  priceAmount?: {
    amount: number;
    currency: string;
  };
  percentage?: string;
}

export type CustomPricingPublicData = Pick<
  CustomPricing,
  'flexibleDate' | 'overrideType' | 'percentage' | 'reason' | 'timeOfDay'
> & {
  priceAmount?: number;
  date?: string;
  startTime?: string;
  endTime?: string;
};
