import React from 'react';

import { Map } from 'share-tribe/components';
import Listing from 'venue/models/Listing.model';

import css from './LocationSection.module.css';

interface LocationSectionProps {
  listing: Listing;
}

const LocationSection = ({ listing }: LocationSectionProps) => {
  const {
    geolocation,
    publicData: { location },
  } = listing.attributes;

  if (!geolocation || !location) {
    return null;
  }

  return (
    <div className={css.root}>
      <Map address={location.address} center={geolocation} />
    </div>
  );
};

export default LocationSection;
