import React from 'react';
import Markdown from 'react-markdown';

import pluralize from 'venue/lib/utils/pluralize.util';
import Listing from 'venue/models/Listing.model';

import css from '../DetailSection.module.css';

interface PoliciesSectionProps {
  listing: Listing;
}

const PoliciesSection = ({ listing }: PoliciesSectionProps) => {
  const {
    cancellationDaysInAdvance,
    cancellationPolicy,
    acceptancePeriod,
    daysBeforeBooking,
    advanceNotice,
  } = listing.attributes.publicData;
  return (
    <ul className={css.detailsList}>
      <li>
        <header className={css.detailsItemHeader}>
          <h6>Booking Advance Notice</h6>
          <strong>
            {daysBeforeBooking} {pluralize(daysBeforeBooking, 'day')}
          </strong>{' '}
          <em>prior to event</em>
        </header>
        <div className={css.detailsDescription}>
          <Markdown disallowedElements={['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}>
            {advanceNotice}
          </Markdown>
        </div>
      </li>
      <li>
        <header className={css.detailsItemHeader}>
          <h6>Acceptance Period Policy</h6>
        </header>
        <div className={css.detailsDescription}>
          <Markdown disallowedElements={['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}>
            {acceptancePeriod}
          </Markdown>
        </div>
      </li>
      <li>
        <header className={css.detailsItemHeader}>
          <h6>Cancelation Policy</h6>
          <strong>
            {cancellationDaysInAdvance} {pluralize(cancellationDaysInAdvance, 'day')}
          </strong>{' '}
          <em>prior to event</em>
        </header>
        <div className={css.detailsDescription}>
          <Markdown disallowedElements={['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}>
            {cancellationPolicy}
          </Markdown>
        </div>
      </li>
    </ul>
  );
};

export default PoliciesSection;
