export const timeOfDayData = [
  { timeOfDay: '12:00 AM', timestamp: 0 },
  { timeOfDay: '1:00 AM', timestamp: 3600000 },
  { timeOfDay: '2:00 AM', timestamp: 7200000 },
  { timeOfDay: '3:00 AM', timestamp: 10800000 },
  { timeOfDay: '4:00 AM', timestamp: 14400000 },
  { timeOfDay: '5:00 AM', timestamp: 18000000 },
  { timeOfDay: '6:00 AM', timestamp: 21600000 },
  { timeOfDay: '7:00 AM', timestamp: 25200000 },
  { timeOfDay: '8:00 AM', timestamp: 28800000 },
  { timeOfDay: '9:00 AM', timestamp: 32400000 },
  { timeOfDay: '10:00 AM', timestamp: 36000000 },
  { timeOfDay: '11:00 AM', timestamp: 39600000 },
  { timeOfDay: '12:00 PM', timestamp: 43200000 },
  { timeOfDay: '1:00 PM', timestamp: 46800000 },
  { timeOfDay: '2:00 PM', timestamp: 50400000 },
  { timeOfDay: '3:00 PM', timestamp: 54000000 },
  { timeOfDay: '4:00 PM', timestamp: 57600000 },
  { timeOfDay: '5:00 PM', timestamp: 61200000 },
  { timeOfDay: '6:00 PM', timestamp: 64800000 },
  { timeOfDay: '7:00 PM', timestamp: 68400000 },
  { timeOfDay: '8:00 PM', timestamp: 72000000 },
  { timeOfDay: '9:00 PM', timestamp: 75600000 },
  { timeOfDay: '10:00 PM', timestamp: 79200000 },
  { timeOfDay: '11:00 PM', timestamp: 82800000 },
];

export const availableEndTimes = (startTime: number) => {
  return timeOfDayData.filter((data) => data.timestamp > startTime);
};

export const getTimeOfDay = (timestamp: number) => {
  return timeOfDayData.find((data) => data.timestamp === timestamp);
};

export const convertTimeStampToTime = (timestamp: string) => {
  const time = getTimeOfDay(parseInt(timestamp));
  return time ? time.timeOfDay : '';
};
