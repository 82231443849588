import moment from 'moment';

import config from 'share-tribe/config/configDefault';
import { types as sdkTypes } from 'share-tribe/util/sdkLoader';
import {
  CustomPriceOverrideType,
  CustomPricing,
  CustomPricingPublicData,
  FlexibleDateOption,
} from 'venue/models/CustomPricing.model';
import PublicData from 'venue/models/PublicData.model';
const { Money } = sdkTypes;

export const getOverrideSentence = (overrideType: CustomPriceOverrideType) => {
  switch (overrideType) {
    case CustomPriceOverrideType.PercentageHourly:
      return 'added to price per hour';
    case CustomPriceOverrideType.PercentageOverallTotal:
      return 'added to your final total';
    case CustomPriceOverrideType.PercentageOffering:
      return 'added to offerings chosen';
    default:
      return '';
  }
};

export const getCustomPricingInitialValue = ({ customPricing }: PublicData): CustomPricing[] => {
  if (customPricing) {
    return customPricing.map((item) => ({
      ...item,
      priceAmount: item.priceAmount
        ? new Money(Number(item.priceAmount), config.currency)
        : undefined,
      date: item.date ? { date: new Date(item.date) } : undefined,
      startTime: item.startTime ? parseInt(item.startTime) : undefined,
      endTime: item.endTime ? parseInt(item.endTime) : undefined,
    }));
  }
  return [];
};

export const convertCustomPricingForSubmit = (
  customPricing: CustomPricing[] = []
): CustomPricingPublicData[] => {
  return sortDaysOfWeek(
    customPricing
      .map((item) => {
        if (
          item.flexibleDate &&
          item.overrideType &&
          item.timeOfDay &&
          (item.percentage || item.priceAmount)
        ) {
          return {
            ...item,
            priceAmount: item.priceAmount?.amount,
            date: item.date?.date.toDateString(),
            startTime: item.startTime?.toString(),
            endTime: item.endTime?.toString(),
          };
        }
        return null as unknown as CustomPricingPublicData;
      })
      .filter(Boolean)
  );
};

/**
 * Retrieves the custom pricing for the selected day.
 *
 * @param customPricing - An array of custom pricing data.
 * @param startDate - The selected date.
 * @returns The custom pricing data for the selected day, if found. Otherwise, undefined.
 */
export const getCustomPricingForDaySelected = ({
  customPricing = [],
  startDate,
  endDate,
}: {
  customPricing?: CustomPricingPublicData[];
  startDate: Date;
  endDate: Date;
}) => {
  const date = moment(startDate).format('YYYY-MM-DD');
  const dayOfWeek = moment(startDate).format('dddd').toLowerCase();
  // Date comparison must come first so that it trumps the day of week comparison
  const customPrice = customPricing.find(
    (pricing) =>
      (pricing.date && moment(new Date(pricing.date)).format('YYYY-MM-DD') === date) ||
      pricing.flexibleDate.toLowerCase() === dayOfWeek
  );

  if (customPrice && customPrice.startTime && customPrice.endTime) {
    const startTimeDate = moment(startDate);
    const endTimeDate = moment(endDate);
    const customPriceStartTime = parseInt(customPrice.startTime);
    const customPriceEndTime = parseInt(customPrice.endTime);

    const selectedStartTimeFromMidnight =
      endTimeDate.millisecond() +
      startTimeDate.second() * 1_000 +
      startTimeDate.minute() * 60_000 +
      startTimeDate.hour() * 3_600_000;
    const selectedEndTimeFromMidnight =
      endTimeDate.millisecond() +
      endTimeDate.second() * 1_000 +
      endTimeDate.minute() * 60_000 +
      endTimeDate.hour() * 3_600_000;

    if (
      (selectedStartTimeFromMidnight >= customPriceStartTime &&
        selectedStartTimeFromMidnight < customPriceEndTime) ||
      (selectedEndTimeFromMidnight < customPriceEndTime &&
        selectedEndTimeFromMidnight >= customPriceStartTime)
    ) {
      return customPrice;
    }

    return undefined;
  }

  return customPrice;
};

const daysOfWeek = [
  FlexibleDateOption.Monday,
  FlexibleDateOption.Tuesday,
  FlexibleDateOption.Wednesday,
  FlexibleDateOption.Thursday,
  FlexibleDateOption.Friday,
  FlexibleDateOption.Saturday,
  FlexibleDateOption.Sunday,
];

export const getCustomPricingDataForListing = (customPricing: CustomPricingPublicData[]) => {
  const specificDates = customPricing.filter(
    (item) => item.flexibleDate === FlexibleDateOption.SpecificDate
  );

  const flexibleDates = customPricing.filter(
    (item) => item.flexibleDate !== FlexibleDateOption.SpecificDate
  );

  return {
    specificDates,
    daysOfWeek: sortDaysOfWeek(flexibleDates),
  };
};

const sortDaysOfWeek = (arr: CustomPricingPublicData[]) => {
  const n = arr.length;
  for (let i = 0; i < n - 1; i++) {
    for (let j = 0; j < n - i - 1; j++) {
      if (daysOfWeek.indexOf(arr[j].flexibleDate) > daysOfWeek.indexOf(arr[j + 1].flexibleDate)) {
        [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
      }
    }
  }
  return arr;
};
