import {
  faBurger,
  faDollarSign,
  faUserGroup,
  faWineGlass,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import formatCurrency from 'venue/lib/utils/formatCurrency.util';
import Listing from 'venue/models/Listing.model';

import css from './QuickSummary.module.css';

interface QuickSummaryProps {
  listing: Listing;
}

const QuickSummary = ({ listing }: QuickSummaryProps) => {
  const {
    price,
    publicData: { addons, maxGuests },
  } = listing.attributes;

  return (
    <div className={css.root}>
      <div className={css.item}>
        <FontAwesomeIcon icon={faDollarSign} size="2x" />
        <span>
          <strong>{formatCurrency(price.amount, price.currency)}</strong> per hour
        </span>
      </div>
      <div className={css.item}>
        <FontAwesomeIcon icon={faUserGroup} size="2x" />
        <span>
          Up to <strong>{maxGuests}</strong> people
        </span>
      </div>
      {addons?.includes('foodPackages') ? (
        <div className={css.item}>
          <FontAwesomeIcon icon={faBurger} size="2x" />
          <span>Food packages</span>
        </div>
      ) : null}
      {addons?.includes('drinkPackages') ? (
        <div className={css.item}>
          <FontAwesomeIcon icon={faWineGlass} size="2x" />
          <span>Drink packages</span>
        </div>
      ) : null}
    </div>
  );
};

export default QuickSummary;
