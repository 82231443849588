import clsx from 'clsx';
import React, { ReactNode, useEffect, useState } from 'react';

import Button from 'venue/components/shared/Button/Button';
import Image from 'venue/models/Image.model';

import css from './VenueGallery.module.css';

interface VenueGalleryProps {
  images: Image[];
}

const VenueGallery = ({ images }: VenueGalleryProps) => {
  const imageCount = images.length;
  const [importedComponent, setImportedComponent] = useState<ReactNode>(null);

  useEffect(() => {
    const importComponent = async () => {
      const { Gallery, Item } = await import('react-photoswipe-gallery');
      setImportedComponent(
        <Gallery id="venue-gallery">
          <div
            className={clsx(css.gallery, {
              [css.single]: imageCount === 1,
            })}
          >
            {images.map((image, idx) => {
              const largeImage = image.attributes?.variants['scaled-xlarge'];
              const mediumImage = image.attributes?.variants['scaled-medium'];
              const thumbnailImage = image.attributes?.variants['scaled-small'];

              if (!largeImage || !mediumImage || !thumbnailImage) {
                return null;
              }

              return (
                <Item
                  id={image.id.uuid}
                  original={largeImage?.url}
                  thumbnail={thumbnailImage?.url}
                  width={largeImage.width}
                  height={largeImage.height}
                  key={image.id.uuid}
                >
                  {({ ref, open }) => {
                    return (
                      <div className={clsx(css.thumbnail, { [css.hidden]: idx > 2 })}>
                        {idx === 0 && imageCount > 3 ? (
                          <Button
                            size="small"
                            onClick={open}
                            style="tertiary"
                            classes={css.viewAll}
                          >
                            View all {images.length}
                          </Button>
                        ) : null}
                        <button onClick={open} className={css.trigger}>
                          <img src={mediumImage.url} ref={ref} />
                        </button>
                      </div>
                    );
                  }}
                </Item>
              );
            })}
          </div>
        </Gallery>
      );
    };

    importComponent();
  }, [images]);

  return importedComponent;
};

export default VenueGallery;
