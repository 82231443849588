import React from 'react';

import NamedLink from 'share-tribe/components/NamedLink/NamedLink';
import Alert from 'venue/components/messaging/Alert/Alert';
import Listing from 'venue/models/Listing.model';

interface ActionBarProps {
  listing: Listing;
  isOwnListing: boolean;
  editParams: {
    id: string;
    slug: string;
    type: string;
    tab: string;
  };
}

const ActionBar = ({ listing, isOwnListing, editParams }: ActionBarProps) => {
  const isPendingApproval = listing.attributes.state === 'pendingApproval';
  const isClosed = listing.attributes.state === 'closed';
  const isDraft = listing.attributes.state === 'draft';

  if (isOwnListing) {
    const message = isPendingApproval
      ? 'Your Venue is pending approval.'
      : isClosed
        ? 'Your Venue listing is closed.'
        : isDraft
          ? 'Your Venue listing is a draft.'
          : 'You own and manage this Venue.';
    return (
      <Alert type="info">
        {message} |{' '}
        <NamedLink name="EditListingPage" params={editParams}>
          Edit Venue &raquo;
        </NamedLink>
      </Alert>
    );
  }

  if (isClosed) {
    return <Alert type="error">This Venue is currently closed.</Alert>;
  }

  return null;
};

export default ActionBar;
