import React from 'react';
import Markdown from 'react-markdown';

import Alert from 'venue/components/messaging/Alert/Alert';
import formatCurrency from 'venue/lib/utils/formatCurrency.util';
import { groupOfferingsToCategory } from 'venue/lib/utils/offerings.util';
import { CustomPriceOverrideType } from 'venue/models/CustomPricing.model';
import Listing from 'venue/models/Listing.model';

import css from '../DetailSection.module.css';

interface OfferingsSectionProps {
  listing: Listing;
}

const OfferingsSection = ({ listing }: OfferingsSectionProps) => {
  const items = listing.attributes.publicData.offerings ?? [];

  const customPricing = listing.attributes?.publicData?.customPricing ?? [];

  const hasCustomOfferingPricing = customPricing.some(
    (customPrice) => customPrice.overrideType === CustomPriceOverrideType.PercentageOffering
  );

  if (items.length === 0) {
    return <Alert type="info">No offerings available</Alert>;
  }

  const groupedOfferings = groupOfferingsToCategory(items);

  return (
    <ul className={css.detailsList}>
      {Object.entries(groupedOfferings).map(([category, items], idx) => (
        <li key={`${category}_${idx}`}>
          <header className={css.detailsGroupingHeader}>
            <h5>{category}</h5>
          </header>
          {items.map((item, idx) => (
            <div className={css.detailsItem} key={`${item.name}_${idx}`}>
              <h6>{item.name}</h6>
              <div className={css.detailsPricing}>
                <p>
                  {hasCustomOfferingPricing && 'Starting from '}
                  <strong>{formatCurrency(item.priceAmount)}</strong>{' '}
                  <em>
                    {item.type === 'perPersonPerHour'
                      ? 'per person per hour'
                      : item.type === 'perPerson'
                        ? 'per person'
                        : item.type === 'perHour'
                          ? 'per hour'
                          : `${item.type} price`}
                  </em>
                </p>
              </div>
              <div className={css.detailsDescription}>
                <Markdown disallowedElements={['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}>
                  {item.description}
                </Markdown>
              </div>
            </div>
          ))}
        </li>
      ))}
    </ul>
  );
};

export default OfferingsSection;
