import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import css from './EmptyState.module.css';

interface EmptyStateProps {
  children: React.ReactNode;
  icon: IconDefinition;
}

const EmptyState = ({ children, icon }: EmptyStateProps) => {
  return (
    <div className={css.container}>
      <div className={css.icon}>
        <FontAwesomeIcon icon={icon} size="3x" />
      </div>
      <div className={css.message}>{children}</div>
    </div>
  );
};

export default EmptyState;
