import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import Markdown from 'react-markdown';

import {
  getCustomPricingDataForListing,
  getOverrideSentence,
} from 'venue/lib/utils/customPricing.util';
import { formatDate } from 'venue/lib/utils/dates.util';
import formatCurrency from 'venue/lib/utils/formatCurrency.util';
import { convertTimeStampToTime } from 'venue/lib/utils/timeOfDay.util';
import { CustomPricingPublicData, FlexibleDateOption } from 'venue/models/CustomPricing.model';
import Listing from 'venue/models/Listing.model';

import css from '../DetailSection.module.css';

interface CustomPricingSectionProps {
  listing: Listing;
}

const CustomPricingItem = ({ item }: { item: CustomPricingPublicData }) => {
  return (
    <div className={css.detailsItem}>
      <h6>
        {item.flexibleDate === FlexibleDateOption.SpecificDate
          ? formatDate(item.date!)
          : item.flexibleDate}
      </h6>
      <div className={clsx(css.detailsPricing, { [css.detailsPricingNoBorder]: !item.reason })}>
        {item.startTime && item.endTime ? (
          <p>
            {convertTimeStampToTime(item.startTime)} - {convertTimeStampToTime(item.endTime)}
          </p>
        ) : null}
        <p>
          {item.priceAmount ? (
            <>
              <strong>{formatCurrency(item.priceAmount)}</strong> <em>per hour</em>
            </>
          ) : null}
          {item.percentage ? (
            <>
              <strong>{item.percentage}%</strong> <em>{getOverrideSentence(item.overrideType)}</em>
            </>
          ) : null}
        </p>
      </div>
      {item.reason ? (
        <div className={css.detailsDescription}>
          <Markdown disallowedElements={['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}>
            {item.reason}
          </Markdown>
        </div>
      ) : null}
    </div>
  );
};

const CustomPricingSection = ({ listing }: CustomPricingSectionProps) => {
  const customPricing = listing.attributes.publicData.customPricing ?? [];

  if (customPricing.length === 0) {
    return null;
  }

  const { specificDates, daysOfWeek } = getCustomPricingDataForListing(customPricing);
  return (
    <ul className={css.detailsList}>
      {daysOfWeek.length > 0 ? (
        <li>
          <header className={css.detailsGroupingHeader}>
            <h5>Special Daily Pricing</h5>
          </header>
          {daysOfWeek.map((item, idx) => (
            <CustomPricingItem item={item} key={`${item.flexibleDate}_${idx}`} />
          ))}
        </li>
      ) : null}
      {specificDates.length > 0 ? (
        <li>
          <header className={css.detailsGroupingHeader}>
            <h5>Date Pricing</h5>
          </header>
          {specificDates.map((item, idx) => (
            <div key={`${item.flexibleDate}_${idx}`}>
              {/* Only show those that are today and future */}
              {moment(item.date).diff(moment(), 'days') >= 0 ? (
                <CustomPricingItem item={item} />
              ) : null}
            </div>
          ))}
        </li>
      ) : null}
    </ul>
  );
};

export default CustomPricingSection;
