import config from 'share-tribe/config/configDefault';
import { types as sdkTypes } from 'share-tribe/util/sdkLoader';
import { CustomPriceOverrideType } from 'venue/models/CustomPricing.model';
import Listing from 'venue/models/Listing.model';
import Offering, { OfferingPublicData } from 'venue/models/Offering.model';
import PublicData from 'venue/models/PublicData.model';

import { getCustomPricingForDaySelected } from './customPricing.util';
const { Money } = sdkTypes;

export const getOfferingsInitialValue = ({ offerings }: PublicData): Offering[] => {
  if (offerings) {
    return offerings.map((item) => ({
      ...item,
      taxLiability: item.taxLiability ?? 'None',
      gratuity: item.gratuity ?? 'None',
      priceAmount: new Money(Number(item.priceAmount), config.currency),
    }));
  }

  return [];
};

export const convertOfferingsForSubmit = (offerings: Offering[]): OfferingPublicData[] => {
  if (offerings) {
    return offerings
      .map((offering) => {
        if (offering.priceAmount && offering.name && offering.type && offering.category) {
          return {
            ...offering,
            gratuity: offering.gratuity ?? 'None',
            taxLiability: offering.taxLiability ?? 'None',
            priceAmount: offering.priceAmount.amount ?? 0,
          };
        }
        return null as unknown as OfferingPublicData;
      })
      .filter(Boolean);
  }

  return [];
};

export const groupOfferingsToCategory = (
  offerings: OfferingPublicData[]
): Record<string, OfferingPublicData[]> => {
  return offerings.reduce((acc: Record<string, OfferingPublicData[]>, item) => {
    const category = item.category;
    if (acc[category]) {
      acc[category].push(item);
    } else {
      acc[category] = [item];
    }
    return acc;
  }, {});
};

/**
 * Adjusts the offering price based on a percentage value.
 * If a percentage is provided, the price will be adjusted by adding the percentage value to it.
 * If no percentage is provided, the price will be divided by 100.
 *
 * @param price - The original price of the offering.
 * @param percentage - The percentage value to adjust the price by (optional).
 * @returns The adjusted price as a string with 2 decimal places.
 */
const adjustOfferingPriceMaybe = (price: number, percentage?: string) => {
  if (percentage) {
    const percentageValue = parseInt(percentage);
    return ((price + price * (percentageValue / 100)) / 100).toFixed(2);
  }
  return (price / 100).toFixed(2);
};

export const getOfferingOptions = ({
  listing,
  startDate,
  endDate,
}: {
  listing: Listing;
  startDate: Date;
  endDate: Date;
}) => {
  let percentage: undefined | string;

  const offerings = listing.attributes?.publicData?.offerings;
  const customPricing = listing.attributes?.publicData?.customPricing;
  const customPricingForDay = getCustomPricingForDaySelected({ customPricing, startDate, endDate });

  if (customPricingForDay?.overrideType === CustomPriceOverrideType.PercentageOffering) {
    percentage = customPricingForDay?.percentage;
  }

  if (offerings?.length) {
    const groupedOfferings = groupOfferingsToCategory(offerings);
    return Object.entries(groupedOfferings).reduce(
      (acc: Record<string, { label: string; key: string }[]>, [category, items]) => {
        acc[category] = items.map((item, index) => ({
          label: `${item.name} ($${adjustOfferingPriceMaybe(item.priceAmount, percentage)} / ${
            item.type === 'perPersonPerHour'
              ? 'person per hour'
              : item.type === 'perPerson'
                ? 'person'
                : item.type === 'perHour'
                  ? 'hour'
                  : item.type
          })`,
          key: `${category}::${item.name}::${index}`,
        }));

        return acc;
      },
      {}
    );
  }

  return null;
};
